.bookingBtn {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  /* background-color: rgb(156,39,176); */
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  border: 3px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 15px;
}

.bookingBtnIcon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}

.bookingBtn:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.bookingBtn:hover .bookingBtnIcon {
  transform: translate(4px);
}

.bookingBtn:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.bookingBtn::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}
