* {
  padding: 0;
  margin: 0;
  font-size: 13px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.p-autocomplete-panel {
  z-index: 1300 !important;
}

.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.3rem 0 0 0 !important;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  padding: 0.3rem 1rem !important;
}

.p-autocomplete {
  height: 2.8rem !important;
  width: 100% !important;
}

.p-autocomplete .p-autocomplete-input {
  padding: 0 0 0 9px !important;
  width: 100% !important;
  border-right: none !important;
  border-color: #909090 !important;
}

.p-autocomplete .p-autocomplete-input:is(:focus, :hover) {
  border-color: #212121 !important;
}

.p-autocomplete .p-autocomplete-input:focus {
  box-shadow: none !important;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
  border-radius: 0px 4px 4px 0px !important;
}

.p-autocomplete-dropdown.p-button {
  background: transparent !important;
  color: #212121 !important;
  border-left: none !important;
  border-color: #909090 !important;
}

.p-autocomplete.p-invalid.p-component > .p-inputtext {
  border-color: #e24c4c !important;
}
